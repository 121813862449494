<template>
  <div class="">
    <header>
      <div
        class="headfixed"
        style="background-color: #ffffff; height: 110px !important"
      >
        <div class="commonweb1400">
          <div class="head-area clearfix">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng33d3ae2259b90262c9c384a46d70eea46950f113a5950638807daf1f7e421b12.png"
            />
            <div class="head-right">
              <nav>
                <ul>
                  <li class="">
                    <h3>
                      <a href="/"><span>首页</span></a>
                      <i></i>
                    </h3>
                    <div class="head-subnav" style="display: none"></div>
                  </li>

                  <li
                    class="cur"
                  >
                    <h3>
                      <a href="/about" target="_self"><span>关于我们</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/about">企业概况</a>

                      <a href="/about">董事长致辞</a>

                      <a href="/about">组织结构</a>

                      <a href="/about">专家团队</a>

                      <a href="/about">大事记</a>

                      <a href="/about">荣誉资质</a>

                      <a href="/about">合作单位</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/hexin" target="_self"><span>核心板块</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/hexin">热防护与热结构</a>

                      <a href="/hexin">长寿命结构</a>

                      <a href="/hexin">摩擦磨损热机构</a>

                      <a href="/hexin">功能热结构</a>

                      <a href="/hexin">半导体热结构</a>

                      <a href="/hexin">能源热结构</a>

                      <a href="/hexin">低膨胀轻质结构</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/" target="_self"><span>新闻中心</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">新闻动态</a>

                      <a href="/">专题活动</a>

                      <a href="/">通知公告</a>

                      <a href="/">视频中心</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/careers/" target="_blank"
                        ><span>党建与企业文化</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">党群建设</a>

                      <a href="/">企业文化</a>

                      <a href="/">社会责任</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/CPC/organization/" target="_self"
                        ><span>联系我们</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">联系我们</a>

                      <a href="/">人才招聘</a>
                    </div>
                  </li>

                  <li
                    class=""
                  >
                    <h3>
                      <a href="/Infodisclosure/public_bidding/" target="_self"
                        ><span>学术期刊</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none"></div>
                  </li>
                </ul>
                <div
                  class="wrap-line"
                  style="left: 0px; width: 0px; background: #0961cd"
                ></div>
              </nav>
              <div class="search">
                <a href="javascript:;" class="search-toggle"></a>
                <div class="search-box">
                  <!-- <form action="/search/">
                      <input
                        type="text"
                        name="key"
                        placeholder="请输入关键词..."
                        class="search-input"
                      />
                      <input type="submit" value="" class="search-btn" />
                    </form> -->
                </div>
              </div>
              <div class="lang">
                <!--<a href="javascript:;" class="lang-toggle">EN</a>-->
                <a href="/" class="lang-toggle" target="_blank">EN</a>
                <!-- <div class="lang-box">
                    <a href="javascript:;">CN</a>
                    <a href="javascript:;">EN</a>
                  </div> -->
              </div>
              <a href="javascript:;" class="toggle-btn">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-head-bg" style="display: none"></div>
    </header>
    <div class="section_1 flex-col">
      <!-- <div
        class="block_1 flex-row"
        style="position: fixed; top: 0; z-index: 99999; background-color: #fff"
      >
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng33d3ae2259b90262c9c384a46d70eea46950f113a5950638807daf1f7e421b12.png"
        />
        <a class="text_1 nav_item" href="/">首页</a>
        <div class="block_2 flex-col justify-between">
          <a class="text_2 nav_item" href="/about"
            >关于我们

            <div class="text-wrapper_1 flex-row menu_more">
              <span class="text_10">企业概况</span>
              <span class="text_11">董事长致辞</span>
              <span class="text_12">组织结构</span>
              <span class="text_13">专家团队</span>
              <span class="text_14">大事记</span>
              <span class="text_15">荣誉资质</span>
              <span class="text_16">合作单位</span>
            </div>
          </a>
          <div class="box_1 flex-col"></div>
        </div>
        <a class="text_3 nav_item" href="/hexin">核心板块</a>
        <a class="text_4 nav_item" href="/">新闻中心</a>
        <a class="text_5 nav_item" href="/">党建与企业文化</a>
        <a class="text_6 nav_item" href="/">联系我们</a>
        <a class="text_7 nav_item" href="/">学术期刊</a>
        <div class="block_3 flex-row">
          <span class="text_8">请输入搜索对象</span>
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng7ac28fd99a71c49484e5a5771d95bec3d936d86a6ee6ac161112dc73b32c171c.png"
          />
        </div>
        <div class="block_4 flex-row justify-between">
          <span class="text_9">简体中文</span>
          <img
            class="thumbnail_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngeba7b353488249463cd45f7234fbeabf1c8b8611d04091b3ef3a86792e433035.png"
          />
        </div>
      </div> -->

      <div class="block_5 flex-col">
        <span class="text_17">Company&nbsp;Profile</span>
        <span class="text_18">企业概况</span>
        <div class="block_6 flex-row">
          <span class="text_19">
            鑫垚公司依托西北工业大学陶瓷基复合材料工程中心成立，在航空发动机、航天发动机、飞行器防热结构、太空轻质结构、刹车制动、核能利用、光伏电子七大领域有力支撑着我国新一代武器装备和新型能源的发展，现拥有阎良基地（产业化基地）和高新基地（研发基地）两大基地。目前，国投控股的鑫垚，已成为保障国家产业安全、国防安全的重要力量之一。
          </span>
          <div class="box_2 flex-col"></div>
        </div>
      </div>
      <div class="block_7 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_20">Chairman's&nbsp;words</span>
          <span class="text_21">董事长致辞</span>
        </div>
        <div class="section_2 flex-row">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng60810f720bff173f0a9e3f2cc89ce668471f2fd8b4ec1d4c11bab4a68897a4dc.png"
          />
          <span class="paragraph_1">
            按照董事会的战略部署，以“成为中国碳陶材料高端产品及规模化的首选供应商”、“成为中国乃至世界碳陶行业领导者”、“成为有社会责任感的中国高科技企业”，为战略目标，把握强国建设之“2025中国制造”、“重大装备制造”、“一带一路”、“工业4.0体系”等重要战略机遇、国家战略性新兴材料产业发展契机，深化公司改制，加快公司上市，坚持“技术规划、产品规划双引领”、“技术开发、市场开发两手抓”“技术创新、管理创新双驱动”、“产品研制、批产能力两提升”的发展战略。以面向市场和客户的快速反应为目标，通过新区建设打造集研发设计、先进制造、智能决策于一体的信息化、智能化、自动化研究、试验、生产的智慧园区。
            <br />
            <br />
          </span>
        </div>
      </div>
      <div class="block_8 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_22">Organization</span>
          <span class="text_23">组织架构</span>
        </div>
        <div class="box_3 flex-row justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd9759fd33ccfafac839a1972fff7c23e561580569e83e64d408dd86abed86651.png"
            />
          </div>
          <span class="paragraph_2">
            按照董事会的战略部署，以“成为中国碳陶材料高端产品及规模化的首选供应商”、“成为中国乃至世界碳陶行业领导者”、“成为有社会责任感的中国高科技企业”，为战略目标，把握强国建设之“2025中国制造”、“重大装备制造”、“一带一路”、“工业4.0体系”等重要战略机遇、国家战略性新兴材料产业发展契机，深化公司改制，加快公司上市，坚持“技术规划、产品规划双引领”、“技术开发、市场开发两手抓”“技术创新、管理创新双驱动”、“产品研制、批产能力两提升”的发展战略。以面向市场和客户的快速反应为目标，通过新区建设打造集研发设计、先进制造、智能决策于一体的信息化、智能化、自动化研究、试验、生产的智慧园区。
            <br />
            <br />
          </span>
        </div>
      </div>
      <div class="block_9 flex-col">
        <div class="section_3 flex-row">
          <div class="text-group_3 flex-col justify-between">
            <span class="text_24">expert&nbsp;team</span>
            <span class="text_25">专家团队</span>
          </div>
        </div>
        <div class="section_4 flex-row justify-between">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng43aa18b049a31b4476f43abbcaffbfe0c1e79f5bb9d33a5062c5f488273eb43e.png"
          />
          <div class="list_1 flex-row">
            <div
              class="list-items_1 flex-col"
              :style="{ background: item.lanhuBg0 }"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <div v-if="item.slot1 === 1" class="group_1 flex-col"></div>
            </div>
          </div>
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng377f20654541960efaad6c6d5622ae908748fae71512348abf206858a609e732.png"
          />
        </div>
        <div class="section_5 flex-row">
          <div class="group_2 flex-col">
            <span class="text_26">专家名家</span>
            <div class="text-group_4 flex-col justify-between">
              <span class="text_27">科学院院士；副院长；</span>
              <span class="text_28">
                进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新没。
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="block_10 flex-col">
        <div class="text-group_5 flex-col justify-between">
          <span class="text_29">Memorabilia</span>
          <span class="text_30">大事记</span>
        </div>
        <div class="text-wrapper_2 flex-row">
          <span class="text_31">2004年</span>
          <span class="text_32">2006年</span>
          <span class="text_33">2013年</span>
        </div>
        <div class="image-wrapper_2 flex-row justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
          />
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
          />
        </div>
        <img
          class="thumbnail_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8bec825072629ce0d1cde9f54421c6203661bfab0bab22a6a93159a73eb462f9.png"
        />
        <div class="box_4 flex-row">
          <div class="block_11 flex-col justify-between">
            <span class="text_34">1991年</span>
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
            />
            <span class="paragraph_3">
              张立同院士
              <br />
              组建团队
              <br />
            </span>
          </div>
          <div class="block_12 flex-col justify-between">
            <span class="text_35">1995年</span>
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
            />
          </div>
          <span class="paragraph_4">
            获批
            <br />
            国防重点
          </span>
          <div class="block_13 flex-col justify-between">
            <span class="text_36">2005年</span>
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
            />
            <span class="paragraph_5">
              获国家技术
              <br />
              发明一等奖
            </span>
          </div>
          <span class="paragraph_6">
            国家工程化
            <br />
            项目立项
          </span>
          <div class="block_14 flex-col justify-between">
            <span class="text_37">2011年</span>
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
            />
            <span class="paragraph_7">
              鑫垚公司成立
              <br />
              (产业化示范)
            </span>
          </div>
          <div class="block_15 flex-col">
            <span class="paragraph_8">
              获批国家工程
              <br />
              研究中心(工程化)
            </span>
            <span class="text_38">2019年</span>
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
            />
            <span class="paragraph_9">
              国投控股鑫垚公司
              <br />
              (产业化)
            </span>
          </div>
          <img
            class="image_11"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngeea4d3e50f6abf90174e497e69e80b34e7bdc491f6ffaca4872e035f55dd7ccc.png"
          />
        </div>
        <span class="paragraph_10">
          国家科研
          <br />
          项目立项
        </span>
        <img
          class="thumbnail_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngf9e599f8172839be0807f2f23ad704c9c005c217a5a72d13f3efc594c14d0dd7.png"
        />
      </div>
    </div>
    <div class="section_6 flex-col">
      <div class="text-group_6 flex-col justify-between">
        <span class="text_39">Honor</span>
        <span class="text_40">荣誉资质</span>
      </div>
      <div class="grid_1 flex-row">
        <div
          class="list-items_2 flex-col"
          v-for="(item, index) in loopData1"
          :key="index"
        >
          <div class="image-wrapper_3 flex-col">
            <img
              v-if="item.slot2 === 2"
              class="image_12"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9f481b8982606b25fbb8e88ff298b2d8a009bc370575b4f8e64556e19619ee71.png"
            />
            <img
              v-if="item.slot1 === 1"
              class="image_13"
              referrerpolicy="no-referrer"
              :src="item.specialSlot1.lanhuimage0"
            />
          </div>
          <span class="text_41" v-html="item.lanhutext0"></span>
        </div>
      </div>
    </div>
    <div class="section_7 flex-col">
      <div class="box_5 flex-col">
        <div class="group_3 flex-row">
          <div class="text-group_7 flex-col justify-between">
            <span class="text_42">Partner</span>
            <span class="text_43">合作单位</span>
          </div>
        </div>
        <div class="group_4 flex-row justify-between">
          <div class="image-wrapper_4 flex-col">
            <img
              class="image_14"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng51eed0905061e29cb28f04055794c1a605194a79ca0f7e5a9a000d2f78896caf.png"
            />
          </div>
          <div class="block_16 flex-col">
            <div class="block_17 flex-col"></div>
          </div>
          <div class="image-wrapper_5 flex-col">
            <img
              class="image_15"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd980ee0c66a334bb1720487b1adcf9225220c65a9d3b7b436c1872b19a9b3218.png"
            />
          </div>
        </div>
        <div class="group_5 flex-row justify-between">
          <div class="image-wrapper_6 flex-col">
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2ba34114ad59170f99873f1822c6d4c92e0536f0ba0dbde54381aae7acfafc67.png"
            />
          </div>
          <div class="section_8 flex-col">
            <div class="block_18 flex-col"></div>
          </div>
          <div class="image-wrapper_7 flex-col">
            <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng89e2109bd163f4be9df1f286425fe8f2e7df96071167bf09731370fc18efe3bc.png"
            />
          </div>
        </div>
      </div>
      <div class="box_6 flex-col">
        <div class="group_6 flex-row justify-between">
          <img
            class="image_18"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng5d2a3b74b767aba4e0b1ca9996286c6fe007801dbbda036d9e8403d31d53642c.png"
          />
          <span class="text_44">联系我们</span>
        </div>
        <span class="text_45">400-800-5653</span>
        <div class="group_7 flex-row">
          <div class="text-wrapper_3 flex-col">
            <span class="text_46">关于我们</span>
            <span class="text_47">常见问题</span>
            <span class="text_48">招聘信息</span>
          </div>
          <div class="text-wrapper_4 flex-col">
            <span class="text_49">核心板块</span>
            <span class="text_50">荣誉资质</span>
            <span class="text_51">发展历程</span>
            <span class="text_52">战略规划</span>
            <span class="text_53">使命愿景</span>
          </div>
          <div class="text-wrapper_5 flex-col">
            <span class="text_54">学术期刊</span>
            <span class="text_55">手册下载</span>
            <span class="text_56">宣传视频</span>
          </div>
          <div class="group_8 flex-col justify-between">
            <span class="text_57">联系我们</span>
            <div class="text-group_8 flex-col justify-between">
              <span class="text_58">优惠政策</span>
              <span class="text_59">人才理念</span>
            </div>
          </div>
          <div class="image-text_1 flex-col justify-between">
            <div class="image-wrapper_8 flex-col">
              <img
                class="image_19"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
              />
            </div>
            <span class="text-group_9">官方公众号</span>
          </div>
          <div class="image-text_2 flex-col justify-between">
            <div class="image-wrapper_9 flex-col">
              <img
                class="image_20"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
              />
            </div>
            <span class="text-group_10">官方客服</span>
          </div>
        </div>
        <img
          class="image_21"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng55338e139c08cccaea6ea6001ab0b14b148862b13f0face6cbcd51c303dce4d3.png"
        />
        <span class="text_60">
          ©&nbsp;Copyright&nbsp;2017-2022西安鑫垚陶瓷复合材料有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;陕ICP备11010745号-2&nbsp;
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/53007442048c4508ae340e43fad02f83_mergeImage.png)",
          slot1: 1,
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5b6cf5d3d6704272a63e8c773a23bf2b_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/b6b534487ea9426492886c6fa5f92ef2_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/385558839dce47e889bfd7de22f21822_mergeImage.png)",
        },
      ],
      loopData1: [
        {
          lanhutext0:
            "“&nbsp;碳/陶材料在民用刹车领域应用产业化”项目获全国创新创业",
          specialSlot1: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfd24226cbf06ede21e2308386ed6f8ee5828675bf7c111c85adcc5f8f11fffe9",
          },
          slot1: 1,
        },
        {
          lanhutext0: "院士工作站",
          specialSlot1: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng89bbdf3436dfab10bc820c5bc87b5a57ed4b66b0a8078026b5dcdb5b728de29d",
          },
          slot1: 1,
        },
        {
          lanhutext0: "博士后工作站",
          specialSlot1: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng70ef317e6dadb4d9d242023f313004a8403b1c17a62b929007c577ea10980bf7",
          },
          slot1: 1,
        },
        { lanhutext0: "高新技术企业证书", slot2: 2 },
        {
          lanhutext0: "职业健康安全管理体系",
          specialSlot1: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng53450bfe1bff188218aa4ae0f0bd24ea9ad0c7920d0255a3f1e10940bd285376",
          },
          slot1: 1,
        },
        {
          lanhutext0: "知识产权管理体系认证证书",
          specialSlot1: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5a2abd7b70ddfc69c86b7f72512c17dfc87492712b46026c5803837439bda730",
          },
          slot1: 1,
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style>
.nav_item {
  position: relative;
}
.menu_more {
  width: 100%;
  position: fixed;
  top: -100px;
  left: 0;
  z-index: 999;
  transition: 1s all;
}
.nav_item:hover .menu_more {
  top: 100px;
}
</style>
