<template>
  <div class="page flex-col">
    <header>
      <div
        class="headfixed"
        style="background-color: #ffffff; height: 110px !important"
      >
        <div class="commonweb1400">
          <div class="head-area clearfix">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng33d3ae2259b90262c9c384a46d70eea46950f113a5950638807daf1f7e421b12.png"
            />
            <div class="head-right">
              <nav>
                <ul>
                  <li class="cur">
                    <h3>
                      <a href="/"><span>首页</span></a>
                      <i></i>
                    </h3>
                    <div class="head-subnav" style="display: none"></div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/about" target="_self"><span>关于我们</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/about">企业概况</a>

                      <a href="/about">董事长致辞</a>

                      <a href="/about">组织结构</a>

                      <a href="/about">专家团队</a>

                      <a href="/about">大事记</a>

                      <a href="/about">荣誉资质</a>

                      <a href="/about">合作单位</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/hexin" target="_self"><span>核心板块</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/hexin">热防护与热结构</a>

                      <a href="/hexin">长寿命结构</a>

                      <a href="/hexin">摩擦磨损热机构</a>

                      <a href="/hexin">功能热结构</a>

                      <a href="/hexin">半导体热结构</a>

                      <a href="/hexin">能源热结构</a>

                      <a href="/hexin">低膨胀轻质结构</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/" target="_self"><span>新闻中心</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">新闻动态</a>

                      <a href="/">专题活动</a>

                      <a href="/">通知公告</a>

                      <a href="/">视频中心</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/careers/" target="_blank"
                        ><span>党建与企业文化</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">党群建设</a>

                      <a href="/">企业文化</a>

                      <a href="/">社会责任</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/CPC/organization/" target="_self"
                        ><span>联系我们</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">联系我们</a>

                      <a href="/">人才招聘</a>
                    </div>
                  </li>

                  <li
                    class=""
                  >
                    <h3>
                      <a href="/Infodisclosure/public_bidding/" target="_self"
                        ><span>学术期刊</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none"></div>
                  </li>
                </ul>
                <div
                  class="wrap-line"
                  style="left: 0px; width: 0px; background: #0961cd"
                ></div>
              </nav>
              <div class="search">
                <a href="javascript:;" class="search-toggle"></a>
                <div class="search-box">
                  <!-- <form action="/search/">
                      <input
                        type="text"
                        name="key"
                        placeholder="请输入关键词..."
                        class="search-input"
                      />
                      <input type="submit" value="" class="search-btn" />
                    </form> -->
                </div>
              </div>
              <div class="lang">
                <!--<a href="javascript:;" class="lang-toggle">EN</a>-->
                <a href="/" class="lang-toggle" target="_blank">EN</a>
                <!-- <div class="lang-box">
                    <a href="javascript:;">CN</a>
                    <a href="javascript:;">EN</a>
                  </div> -->
              </div>
              <a href="javascript:;" class="toggle-btn">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-head-bg" style="display: none"></div>
    </header>
    <div class="box_2 flex-col">
      <div class="group_5 flex-col">
        <span class="text_10">ABOUT&nbsp;&nbsp;US</span>
        <span class="text_11">关于我们</span>
        <div class="text-group_1 flex-col justify-between">
          <span class="text_12">国投陶瓷基复合材料研究院</span>
          <span class="text_13">
            国投陶瓷基复合材料研究院是由国投集团、酉安鑫垚、酉北工业大学牵头发起共建的科技创新实体，于2022年11月在西安注册成立，注册资金1亿元。研究院发展面向国家重大战略需求和国民经济主战场,以市场为导向开展科技攻关、成果转化工作。
          </span>
        </div>
        <span class="text_14">了解更多》</span>
      </div>
      <div class="group_6 flex-row">
        <div class="image-text_1 flex-row justify-between">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga872c22fedac145e4e5e22286bbdeabe7519486684ee0d0b0e0380421f7b35a4.png"
          />
          <div class="text-group_2 flex-col justify-between">
            <span class="text_15">300</span>
            <span class="text_16">
              利改无治的证取又知政界及育约又酸况备志从两类传线素情常满与命统候声持常理与天量精金低特在色是形认不军权常支线展加场区过号场期情社率。
            </span>
          </div>
        </div>
        <div class="image-text_2 flex-row justify-between">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga872c22fedac145e4e5e22286bbdeabe7519486684ee0d0b0e0380421f7b35a4.png"
          />
          <div class="text-group_3 flex-col justify-between">
            <span class="text_17">5000+</span>
            <span class="text_18">
              利改无治的证取又知政界及育约又酸况备志从两类传线素情常满与命统候声持常理与天量精金低特在色是形认不军权常支线展加场区过号场期情社率。
            </span>
          </div>
        </div>
        <div class="image-text_3 flex-row justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga872c22fedac145e4e5e22286bbdeabe7519486684ee0d0b0e0380421f7b35a4.png"
          />
          <div class="text-group_4 flex-col justify-between">
            <span class="text_19">20</span>
            <span class="text_20">
              利改无治的证取又知政界及育约又酸况备志从两类传线素情常满与命统候声持常理与天量精金低特在色是形认不军权常支线展加场区过号场期情社率。
            </span>
          </div>
        </div>
        <div class="image-text_4 flex-row justify-between">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga872c22fedac145e4e5e22286bbdeabe7519486684ee0d0b0e0380421f7b35a4.png"
          />
          <div class="text-group_5 flex-col justify-between">
            <span class="text_21">300</span>
            <span class="text_22">
              利改无治的证取又知政界及育约又酸况备志从两类传线素情常满与命统候声持常理与天量精金低特在色是形认不军权常支线展加场区过号场期情社率。
            </span>
          </div>
        </div>
      </div>
      <div class="text-group_6 flex-col justify-between">
        <span class="text_23">xinyaogufen</span>
        <span class="text_24">西安鑫垚陶瓷复合材料股份有限公司</span>
      </div>
      <span class="text_25">
        进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新第没。民复律划位支也学务手集特极这使精同华划规装级组特少得算转党件火片月元型西江我素族走活边治响位如发音北军结先。阶后联段五结造低品文石但程为品律水取可下界有属手由规线调办先复这建况会统备头中严制转细更。并查基型将适眼只在位国当么局共历什作已自力改建名难值子离些象么分住济基常深王便一社。阶后联段五结造低品文石但程为品律水取可下界有属手由规线调办先复这建。
      </span>
      <div class="group_7 flex-row justify-between">
        <div class="text-wrapper_1 flex-col button_hover">
          <span class="text_26">企业历程</span>
        </div>
        <div class="text-wrapper_2 flex-col button_hover">
          <span class="text_27">企业荣誉</span>
        </div>
      </div>
      <div class="group_8 flex-row">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_28">关于国投研究院</span>
          <span class="text_29"
            >About&nbsp;SDIC&nbsp;Research&nbsp;Institute</span
          >
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng456338ddc8647103f3c8e1e239a5e5cf688fe6c31dbf8cc7c3582ce3e940c50d.png"
        />
      </div>
      <div class="text-group_8 flex-col justify-between">
        <span class="text_30">OMER&nbsp;CASE</span>
        <span class="text_31">产品与服务</span>
      </div>
      <div class="group_9 flex-row justify-between">
        <div class="group_10 flex-row">
          <div class="text-group_9 flex-col justify-between">
            <span class="text_32">业务范围</span>
            <span class="text_33">
              进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新第没。民复律划位支也学务手集特极这使精同华划规装级组特少得算转党件火片月元型西江我素族走活边治响位如发音北军结先。
            </span>
          </div>
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng4bec9480c69062c6a5966a123ce94dfb7ca0e482f39144ea071c83800e4085cf.png"
          />
        </div>
        <div class="group_11 flex-col"></div>
      </div>
      <div class="list_1 flex-row">
        <div
          class="list-items_1 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        ></div>
      </div>
      <div class="group_12 flex-col">
        <div class="text-group_10 flex-col justify-between">
          <span class="text_34">news&nbsp;centers</span>
          <span class="text_35">新闻中心</span>
        </div>
        <div class="group_13 flex-row justify-between">
          <div class="text-wrapper_3 flex-col button_hover">
            <span class="text_36">新闻动态</span>
          </div>
          <div class="text-wrapper_4 flex-col button_hover">
            <span class="text_37">专题活动</span>
          </div>
          <div class="text-wrapper_5 flex-col button_hover">
            <span class="text_38">通知公告</span>
          </div>
          <div class="text-wrapper_6 flex-col button_hover">
            <span class="text_39">视频中心</span>
          </div>
        </div>
        <div class="group_14 flex-row justify-between">
          <div class="group_15 flex-col">
            <div class="text-wrapper_9 flex-col">
              <span class="text_42">点击此处请输入标题</span>
            </div>
          </div>
          <div class="group_16 flex-col justify-between">
            <div class="section_1 flex-col justify-end">
              <span class="text_43">点击此处请输入新闻标题</span>
              <span class="text_44">
                进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新没。
              </span>
              <div class="image-text_5 flex-row justify-between">
                <span class="text-group_11">展示更多</span>
                <img
                  class="thumbnail_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng7c203bb17a2c1d531dbb4740fea6c4e619c057f073ed57a51a915d9f248f2d18.png"
                />
              </div>
              <div class="group_17 flex-col"></div>
            </div>
            <div class="section_2 flex-col">
              <span class="text_45">点击此处请输入新闻标题</span>
              <span class="text_46">
                进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新没。
              </span>
              <div class="image-text_6 flex-row justify-between">
                <span class="text-group_12">展示更多</span>
                <img
                  class="thumbnail_4"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng7c203bb17a2c1d531dbb4740fea6c4e619c057f073ed57a51a915d9f248f2d18.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="group_18 flex-row justify-between">
          <div class="group_19 flex-col">
            <span class="text_47">点击此处请输入新闻标题</span>
            <span class="text_48">
              进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新没。
            </span>
            <div class="image-text_7 flex-row justify-between">
              <span class="text-group_13">展示更多</span>
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7c203bb17a2c1d531dbb4740fea6c4e619c057f073ed57a51a915d9f248f2d18.png"
              />
            </div>
          </div>
          <div class="group_20 flex-col">
            <span class="text_49">点击此处请输入新闻标题</span>
            <span class="text_50">
              进方边知声际被展后铁族了多些完非法直号两队始值办加色专型交华表第步土为任装术采进权究效满题题办题任型县较低区严治开族为体属种的示量新没。
            </span>
            <div class="image-text_8 flex-row justify-between">
              <span class="text-group_14">展示更多</span>
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7c203bb17a2c1d531dbb4740fea6c4e619c057f073ed57a51a915d9f248f2d18.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_21 flex-col">
        <div class="box_3 flex-row justify-between">
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng5d2a3b74b767aba4e0b1ca9996286c6fe007801dbbda036d9e8403d31d53642c.png"
          />
          <span class="text_51">联系我们</span>
        </div>
        <span class="text_52">400-800-5653</span>
        <div class="box_4 flex-row">
          <div class="text-wrapper_10 flex-col">
            <span class="text_53">关于我们</span>
            <span class="text_54">企业概况</span>
            <span class="text_55">董事长致辞</span>
            <span class="text_55">组织结构</span>
            <span class="text_55">专家团队</span>
            <span class="text_55">大事记</span>
            <span class="text_55">荣誉资质</span>
            <span class="text_55">合作单位</span>
          </div>
          <div class="text-wrapper_11 flex-col">
            <span class="text_56">核心板块</span>
            <span class="text_57">热防护与热结构</span>
            <span class="text_58">长寿命结构</span>
            <span class="text_59">摩擦磨损热机构</span>
            <span class="text_60">功能热结构</span>
            <span class="text_60">半导体热结构</span>
            <span class="text_60">能源热结构</span>
            <span class="text_60">低膨胀轻质结构</span>
          </div>
          <div class="text-wrapper_12 flex-col">
            <span class="text_61">学术期刊</span>
            <span class="text_62">手册下载</span>
            <span class="text_63">宣传视频</span>
          </div>
          <div class="block_1 flex-col justify-between">
            <span class="text_64">联系我们</span>
            <div class="text-group_15 flex-col justify-between">
              <span class="text_65">优惠政策</span>
              <span class="text_66">人才理念</span>
            </div>
          </div>
          <div class="image-text_9 flex-col justify-between">
            <div class="image-wrapper_1 flex-col">
              <img
                class="image_9"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
              />
            </div>
            <span class="text-group_16">官方公众号</span>
          </div>
          <div class="image-text_10 flex-col justify-between">
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_10"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
              />
            </div>
            <span class="text-group_17">官方客服</span>
          </div>
        </div>
        <img
          class="image_11"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng55338e139c08cccaea6ea6001ab0b14b148862b13f0face6cbcd51c303dce4d3.png"
        />
        <span class="text_67">
          ©&nbsp;Copyright&nbsp;2017-2022西安鑫垚陶瓷复合材料有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;陕ICP备11010745号-2&nbsp;
        </span>
      </div>
      <div class="group_22 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/aa06da2fa3a04fe384d25616e87ae7e2_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f13c7090de734b2e920bd0e2e8674d78_mergeImage.png)",
        },
        {
          lanhuBg0:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e9c4ac34b4c047a29c45d449855f2b2c_mergeImage.png)",
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.css" />
