import Vue from 'vue'
import VueRouter from 'vue-router'
// import about from '../views/lanhu_guanyuwomen/index.vue'
// import lanhu_appletvbeifen4 from '../views/lanhu_appletvbeifen4/index.vue'
import index from '../views/lanhu_shouye/index.vue'
import hexin from '../views/lanhu_appletvbeifen3/index.vue'
import about from '../views/lanhu_guanyuwomenxiding/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: '首页',
    component: index
  },
  {
    path: '/about',
    name: '关于我们',
    component: about
  },
  {
    path: '/hexin',
    name: '核心板块',
    component: hexin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
