<template>
  <div class="page flex-col">
    <header>
      <div
        class="headfixed"
        style="background-color: #ffffff; height: 110px !important"
      >
        <div class="commonweb1400">
          <div class="head-area clearfix">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng33d3ae2259b90262c9c384a46d70eea46950f113a5950638807daf1f7e421b12.png"
            />
            <div class="head-right">
              <nav>
                <ul>
                  <li class="">
                    <h3>
                      <a href="/"><span>首页</span></a>
                      <i></i>
                    </h3>
                    <div class="head-subnav" style="display: none"></div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/about" target="_self"><span>关于我们</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/about">企业概况</a>

                      <a href="/about">董事长致辞</a>

                      <a href="/about">组织结构</a>

                      <a href="/about">专家团队</a>

                      <a href="/about">大事记</a>

                      <a href="/about">荣誉资质</a>

                      <a href="/about">合作单位</a>
                    </div>
                  </li>

                  <li
                    class="cur"
                  >
                    <h3>
                      <a href="/hexin" target="_self"><span>核心板块</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/hexin">热防护与热结构</a>

                      <a href="/hexin">长寿命结构</a>

                      <a href="/hexin">摩擦磨损热机构</a>

                      <a href="/hexin">功能热结构</a>

                      <a href="/hexin">半导体热结构</a>

                      <a href="/hexin">能源热结构</a>

                      <a href="/hexin">低膨胀轻质结构</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/" target="_self"><span>新闻中心</span></a>
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">新闻动态</a>

                      <a href="/">专题活动</a>

                      <a href="/">通知公告</a>

                      <a href="/">视频中心</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/careers/" target="_blank"
                        ><span>党建与企业文化</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">党群建设</a>

                      <a href="/">企业文化</a>

                      <a href="/">社会责任</a>
                    </div>
                  </li>

                  <li
                    class="
                                        "
                  >
                    <h3>
                      <a href="/CPC/organization/" target="_self"
                        ><span>联系我们</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none">
                      <a href="/">联系我们</a>

                      <a href="/">人才招聘</a>
                    </div>
                  </li>

                  <li
                    class=""
                  >
                    <h3>
                      <a href="/Infodisclosure/public_bidding/" target="_self"
                        ><span>学术期刊</span></a
                      >
                      <i></i>
                    </h3>

                    <div class="head-subnav" style="display: none"></div>
                  </li>
                </ul>
                <div
                  class="wrap-line"
                  style="left: 0px; width: 0px; background: #0961cd"
                ></div>
              </nav>
              <div class="search">
                <a href="javascript:;" class="search-toggle"></a>
                <div class="search-box">
                  <!-- <form action="/search/">
                      <input
                        type="text"
                        name="key"
                        placeholder="请输入关键词..."
                        class="search-input"
                      />
                      <input type="submit" value="" class="search-btn" />
                    </form> -->
                </div>
              </div>
              <div class="lang">
                <!--<a href="javascript:;" class="lang-toggle">EN</a>-->
                <a href="/" class="lang-toggle" target="_blank">EN</a>
                <!-- <div class="lang-box">
                    <a href="javascript:;">CN</a>
                    <a href="javascript:;">EN</a>
                  </div> -->
              </div>
              <a href="javascript:;" class="toggle-btn">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-head-bg" style="display: none"></div>
    </header>
    <div class="block_2 flex-col justify-center">
      <div class="text-wrapper_1 flex-col">
        <span class="text_10">核心板块</span>
        <span class="text_11">Core&nbsp;Technology</span>
      </div>
    </div>
    <div class="text-group_1 flex-col justify-between">
      <span class="text_12">Core&nbsp;Technology</span>
      <span class="text_13">核心板块</span>
    </div>
    <div class="grid_1 flex-row">
      <div
        class="list-items_1 flex-col"
        v-for="(item, index) in loopData0"
        :key="index"
      >
        <div
          class="box_4 flex-col"
          :style="{ background: item.lanhuBg1 }"
        ></div>
        <span class="text_14" v-html="item.lanhutext0"></span>
      </div>
    </div>
    <div class="block_3 flex-row">
      <div class="image-text_1 flex-col justify-between">
        <div class="box_5 flex-col"></div>
        <span class="text-group_2">低膨胀轻质结构</span>
      </div>
    </div>
    <div class="block_4 flex-col">
      <div class="group_2 flex-row justify-between">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5d2a3b74b767aba4e0b1ca9996286c6fe007801dbbda036d9e8403d31d53642c.png"
        />
        <span class="text_15">联系我们</span>
      </div>
      <span class="text_16">400-800-5653</span>
      <div class="group_3 flex-row">
        <div class="text-wrapper_2 flex-col">
          <span class="text_17">关于我们</span>
          <span class="text_18">常见问题</span>
          <span class="text_19">招聘信息</span>
        </div>
        <div class="text-wrapper_3 flex-col">
          <span class="text_20">核心板块</span>
          <span class="text_21">荣誉资质</span>
          <span class="text_22">发展历程</span>
          <span class="text_23">战略规划</span>
          <span class="text_24">使命愿景</span>
        </div>
        <div class="text-wrapper_4 flex-col">
          <span class="text_25">学术期刊</span>
          <span class="text_26">手册下载</span>
          <span class="text_27">宣传视频</span>
        </div>
        <div class="block_5 flex-col justify-between">
          <span class="text_28">联系我们</span>
          <div class="text-group_3 flex-col justify-between">
            <span class="text_29">优惠政策</span>
            <span class="text_30">人才理念</span>
          </div>
        </div>
        <div class="image-text_2 flex-col justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
            />
          </div>
          <span class="text-group_4">官方公众号</span>
        </div>
        <div class="image-text_3 flex-col justify-between">
          <div class="image-wrapper_2 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnga62f464f659de7aa0aa33427301e898a6afe8cfd8bc57404358a97560c041f56.png"
            />
          </div>
          <span class="text-group_5">官方客服</span>
        </div>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng55338e139c08cccaea6ea6001ab0b14b148862b13f0face6cbcd51c303dce4d3.png"
      />
      <span class="text_31">
        ©&nbsp;Copyright&nbsp;2017-2022西安鑫垚陶瓷复合材料有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;陕ICP备11010745号-2&nbsp;
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f83301d33510449787c1496f626e972a_mergeImage.png)",
          lanhutext0: "热防护与热结构",
        },
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d57568a23f84417eb4ad28cff5cc5e7c_mergeImage.png)",
          lanhutext0: "长寿命结构",
        },
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cc104ad07fc04b11b09340cec575e630_mergeImage.png)",
          lanhutext0: "摩擦磨损热机构",
        },
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8f1cb24d981340cdb0ea5c4e3e03e72c_mergeImage.png)",
          lanhutext0: "功能热结构",
        },
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/212c7f3e096b445b95a09e0b44b38556_mergeImage.png)",
          lanhutext0: "半导体热结构",
        },
        {
          lanhuBg1:
            "url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f9a922f5ae7f4559bef6ecdbdf5919db_mergeImage.png)",
          lanhutext0: "能源热机构",
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.css" />
